<template>
    <div class="page-ryProbe-list">
        <div
            class="user-header"
            @click="switchAccount"
        >
            <div class="img-box">
                <img
                    :src="ossHost + 'img/logo_min.png'"
                    alt=""
                >
            </div>
            <span>{{name}}</span>
            <span class="switch-account">切换账号</span>
        </div>
        <div class="scan-body">
            <div class="scan-header">
                <div class="scan-search">
                    <Search
                        v-model="value"
                        show-action
                        background="#fff"
                        placeholder="请输入搜索关键词"
                        :clearable="false"
                        maxlength="16"
                        @search="onSearch('')"
                    >
                        <template #action>
                            <div @click="onSearch('')">搜索</div>
                        </template>
                    </Search>
                    <div
                        class="scan-btn"
                        @click="scan"
                    >
                        <img
                            :src="ossHost + 'img/scan.png'"
                            alt=""
                        >
                        扫码
                    </div>
                </div>
                <div class="scan-tab">
                    <Tabs v-model="activeIndex" @click="changeTab()">
                        <Tab
                            :title="title1"
                        ></Tab>
                        <Tab
                            :title="title2"
                        ></Tab>
                    </Tabs>
                </div>
            </div>
            <div class="scan-list-body">
                <CheckListItem
                    v-for="(item, index) in list"
                    :key="index"
                    :item="item"
                />
                <div class="loading-box">
                    <Button
                        loading
                        v-if="loading && !isShowAll"
                        type="default"
                        loading-text="加载中..."
                    />
                    <Button
                        type="default"
                        v-if="!loading && !isShowAll"
                        @click="loadMore"
                    >点击加载更多</Button>
                    <Button
                        type="default"
                        v-if="isShowAll"
                    >{{list.length ? '已全部显示' : '暂无数据'}}</Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Dialog, Search, Toast, Tabs, Tab, Button } from "vant";
import CheckListItem from "@/components/ryProbe/checkListItem";
export default {
    name: "RyProbeList",
    components: {
        Search,
        Tabs,
        Tab,
        CheckListItem,
        Button
    },
    data() {
        const { ossHost } = this.$config.base;
        return {
            ossHost,
            name: "",
            value: "",
            title1: "全部",
            title2: "未通过",
            activeIndex: 0,
            list: [],
            isShowAll: false,
            loading: false
        };
    },
    created() {
        const vm = this;
        const { USER_NAME } = vm.$config.keys;
        const name = vm.$localStorage.getItem(USER_NAME);
        const uuid = vm.$localStorage.getItem('uuid')

        const fType = vm.$strTool.filterFactoryType(uuid);
        if (vm.$config.base.fList.indexOf(fType) > -1) {
            vm.name = name;
            if (!name) {
                vm.$router.replace({
                    path: "/factory/login"
                });
                return;
            }

            !['yingwo', 'wangbo', 'lexing'].includes(fType) && vm.$router.replace({
                path: "/factory/index"
            });
        } else {
            vm.$router.replace({
                path: "404"
            });
        }

        vm.pageNo = 1;
        vm.pageSize = 10;
        vm.weixinInit();
        vm.loadCheckList();
    },
    methods: {
        switchAccount() {
            const vm = this;
            Dialog.confirm({
                title: "提示",
                message: "是否确认退出当前账号？",
                confirmButtonColor: "#32BE32"
            })
                .then(() => {
                    const { USER_NAME } = vm.$config.keys;
                    vm.$localStorage.removeItem(USER_NAME);
                    vm.$router.replace({
                        path: "/factory/login?type=1"
                    });
                })
                .catch(() => {
                    // on cancel
                });
        },

        weixinInit() {
            const vm = this;
            vm.$http({
                type: "get",
                url: `https://m.yudada.com/jssdk/configuration?url=${
                    location.href
                }`
            }).then(res => {
                const { appId, nonceStr, signature, timestamp } = res.data;
                window.wx.config({
                    debug: false,
                    appId,
                    timestamp,
                    nonceStr,
                    signature,
                    jsApiList: ["scanQRCode"]
                });
            });
        },

        scan() {
            const vm = this;
            window.wx.scanQRCode({
                needResult: 1,
                scanType: ["barCode", "qrCode"],
                success: function(res) {
                    const result = res.resultStr;
                    if (!result) {
                        Dialog.confirm({
                            title: "提示",
                            message: "非探头条形码~",
                            confirmButtonColor: "#32BE32",
                            showCancelButton: false
                        });
                        return;
                    }
                    vm.onSearch(result);
                }
            });
        },
        onSearch(code) {
            const vm = this;
            // if (vm.value.trim().length != 16) {
            //     Toast.fail({
            //         message: '请输入16位ID编码查询',
            //         forbidClick: false,
            //         overlay: true,
            //         duration: 2000,
            //     });
            //     return
            // }
            vm.tt = Toast.loading({
                message: "加载中...",
                forbidClick: true,
                overlay: true,
                duration: 0
            });
            let probeId = code || vm.value.trim();
            `${probeId}`.split(',')[1] && (probeId = probeId.split(',')[1])
            let len = `${probeId}`.length > 6 ? 9 - `${probeId}`.length : 8 - `${probeId}`.length;
            if (len > 0 && `${probeId}`.length != 8) {
                len == 1 && (probeId = `0${probeId}`)
                len == 2 && (probeId = `00${probeId}`)
                len == 3 && (probeId = `000${probeId}`)
                len == 4 && (probeId = `0000${probeId}`)
                len == 5 && (probeId = `00000${probeId}`)
                len == 6 && (probeId = `000000${probeId}`)
                len == 7 && (probeId = `0000000${probeId}`)
            }
            vm.$http({
                type: "get",
                url: `${vm.$config.base.PROBE_BASE_URL}probe`,
                params: { probeId }
            }).then(res => {
                vm.tt.clear();
                if (res.data) {
                    vm.$router.push({
                        path: "/ryProbe/info",
                        query: { uuid: probeId }
                    });
                } else {
                    Toast.fail({
                        message: '未找到对应探头的质检记录，请重新确认',
                        forbidClick: true,
                        overlay: true,
                        duration: 2000,
                    });
                }
            });
        },
        onCancel() {},
        changeTab() {
            const vm = this;
            if (vm.activeIndex > -1) {
                vm.list = [];
                vm.pageNo = 1;
                vm.isShowAll = false;
                vm.loadMore();
            }
        },
        loadMore() {
            const vm = this;
            if (vm.loading) {
                return;
            }
            vm.loadCheckList();
        },
        loadCheckList() {
            const vm = this;
            vm.loading = true;
            vm.$http({
                type: "get",
                url: `${vm.$config.base.PROBE_BASE_URL}probeList`,
                params: {
                    pageNo: vm.pageNo,
                    pageSize: vm.pageSize,
                    type: vm.activeIndex + 1
                },
                except: true
            }).then(res => {
                if (!res.data) {
                    Toast("网络错误");
                    return;
                }
                const { probeList, totalCount, failedCount } = res.data || {};
                vm.title1 = `全部(${totalCount})`;
                vm.title2 = `未通过(${failedCount})`;
                vm.list = vm.list.concat(probeList || []);
                vm.loading = false;
                if (!probeList || probeList.length < vm.pageSize) {
                    vm.isShowAll = true;
                } else {
                    vm.pageNo++;
                }
            });
        }
    }
};
</script>

<style lang="less">
@import "@/less/base.less";
.page-ryProbe-list {
    width: 100%;
    height: 100%;
    background: @themeColor;
    .user-header {
        position: relative;
        padding: 0 0.1rem 0 0.7rem;
        display: flex;
        height: 0.73rem;
        align-items: center;
        font-size: 0.2rem;
        color: @blank;
        justify-content: space-between;
        .img-box {
            position: absolute;
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 0.25rem;
            background: @blank;
            left: 0.1rem;
            top: 0.1rem;
            line-height: 0.5rem;
            text-align: center;
            img {
                display: inline-block;
                width: 80%;
            }
        }

        .switch-account {
            font-size: 0.12rem;
        }
    }

    .scan-body {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0.7rem;
        background: @blank;
        z-index: 1;
        border-radius: 0.25rem 0.25rem 0 0;
        padding-top: 1rem;
        font-size: 0.14rem;
        .scan-header {
            position: absolute;
            left: 0;
            right: 0;
            top: 0.15rem;
            padding: 0 10px;
            .van-search {
                padding: 0;
                border: 1px solid #919191;
                margin-right: 0.9rem;
                .van-search__content {
                    background-color: #fff;
                    .van-field__left-icon .van-icon {
                        font-size: 0.2rem;
                    }
                }
                .van-search__action {
                    color: #919191;
                }
            }
            .scan-btn {
                position: absolute;
                right: 10px;
                display: flex;
                top: 0;
                font-size: 0.18rem;
                color: @themeColor;
                font-weight: 600;
                line-height: 36px;
                height: 36px;
                justify-content: center;
                align-items: center;
                img {
                    display: inline-block;
                    width: 0.25rem;
                    height: 0.25rem;
                    margin-right: 0.12rem;
                }
            }
            .scan-tab {
                .van-tabs__line {
                    background-color: @themeColor;
                    bottom: 5px;
                }
                .van-tabs__nav {
                    padding-bottom: 0;
                }
                .van-tabs--line .van-tabs__wrap {
                    height: 0.44rem;
                }
            }
        }
        .scan-list-body {
            height: 100%;
            overflow: auto;
            .loading-box {
                text-align: center;
                padding: 0.1rem 0;
                .van-button {
                    border: 0;
                }
            }
        }
    }
}
</style>


